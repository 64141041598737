'use client'
import { errorContactForm, successNewsLetter } from '@/components/alerts/AlertConfigurations'
import { showUIAlert, useGetUtmQueryParams, useSubmitForm } from '@/components/forms/FormUtils'
import { RequestedFormsConfigType, useMergeFormsConfig } from '@/components/forms/InputConfigs'
import Loader from '@/components/loader/Loader'
import BaseFormStyled from '@/components/simplified-form/BaseFormStyled'
import NewsletterService from '@/services/NewsletterService'
import React, { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string
  header?: React.ReactNode
  headerSlot?: React.ReactNode
}

const NewsletterFormStyled = ({ className, headerSlot = null, ...props }: Props) => {
  const baseFormInputConfigurations: RequestedFormsConfigType = [
    {
      name: 'full_name',
      className: 'flex-1 min-w-32 text-sm'
    },
    {
      name: 'email',
      className: 'flex-1 min-w-32 text-sm',
      label: 'Correo Electrónico',
      placeholder: 'Correo Electrónico'
    },
    {
      name: 'accept_terms',
      subtype: 'privacy_policy_short',
      className: 'min-w-full pt-1'
    }
  ]
  const inputConfiguration = useMergeFormsConfig(baseFormInputConfigurations)
  const aditionalPayloadUtmData = useGetUtmQueryParams()

  const { handleSubmit, isLoading } = useSubmitForm({
    service: NewsletterService.registerNewsletter,
    aditionalPayloadData: aditionalPayloadUtmData,
    onErrorCallback: () => {
      showUIAlert(errorContactForm)
    },
    onSuccessCallback: () => {
      showUIAlert(successNewsLetter)
    }
  })

  const message = isLoading ? 'Enviando...' : 'Suscribirse'
  return (
    <div className={className} {...props}>
      {isLoading && <Loader />}
      <div className="flex flex-col spaceBetween flex-wrap gap-y-3">
        {headerSlot}
        <BaseFormStyled
          className="flex-1"
          InputsConfig={inputConfiguration}
          onSubmit={handleSubmit}
          submitText={message}
          submitClassName="text-lg py-2 w-full sm:max-w-[250px]"
          enableRecaptcha
        />
      </div>
    </div>
  )
}

export default NewsletterFormStyled
